/* You can add global styles to this file, and also import other style files */
@use '@angular/material/prebuilt-themes/indigo-pink.css';
@import './assets/styles.themes.scss';
@import '../node_modules/@angular/material/core/theming/theming';

.btn-logout {
  height: 40px !important;
  margin-left: auto !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  line-height: initial !important;
  padding: 6px 20px !important;
}

.btn-login {
  background-color: var(--basic-color) !important;
}

.btn-create {
  background-color: var(--text-color-dark) !important;
}

.btn-subscribe {
  height: 60px !important;
  margin-left: auto !important;
  font-size: 2rem !important;
  font-weight: 700 !important;
  line-height: initial !important;
  float: right;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.mobile-view {
  .mat-vertical-content-container {
    margin: 0 !important;
  }

  mat-step-header[aria-posinset='1'] {
    border-radius: 0 !important;
  }

  .panel {
    min-height: 530px !important;
  }

  .panel-short {
    min-height: 280px !important;
    @media screen and (min-width: 320px) and (max-width: 767px) {
      min-height: 0 !important;
    }
  }

  .panel-long {
    min-height: 620px !important;
  }

  hr {
    margin: 0 !important;
  }

  .mat-vertical-content {
    padding: 0 5px 0px 5px !important;
    background-color: #eeeeee !important;
  }

  .content-modal {
    padding: 15px 40px 15px 40px !important;
  }

  .offset-3 {
    margin: 0px !important;
    padding: 0px !important;
    padding-top: 1rem !important;
  }

  .offset-1 {
    margin: 0px !important;
  }

  .content-bottom .content {
    padding: 0px !important;
  }

  .outer {
    margin: 0px !important;
  }
}

@media print {
  body {
    background-color: #fff !important;
    -webkit-print-color-adjust: exact !important;
  }

  #body {
    background-color: #fff !important;
  }

  .mat-horizontal-content-container {
    background-color: #fff !important;
  }

  .inner i {
    background-color: #fff !important;
  }

  .main-content .content {
    background-color: #fcfcfc !important;
  }

  .voucher-content {
    padding-top: 10em !important;
  }
  .mobile-print {
    .inner {
      max-width: 75vw !important;
    }

    .inner i {
      background-color: #eeeeee !important;
    }
  }
  #voucherTransaction {
    display: flex;
    flex-direction: column;
    align-items: center;
    #voucherArea {
      min-width: 45vw;
      .voucher-content {
        .main-content {
          .inner {
            max-width: 45vw;
            .content {
              .text-center {
                .flex {
                  max-width: 100% !important;
                }
              }
            }
          }
        }
        .content-bottom {
          .inner {
            max-width: 45vw;
          }
        }
      }
    }
  }

  .text-center.pt-3 {
    padding-top: 0px !important;
  }

  .whatsapp-icon,
    // .mat-horizontal-stepper,
    .btn,
    // .mat-step-header,
    .toast-success {
    display: none !important;
  }
  app-footer {
    .mt-3 {
      flex-wrap: nowrap !important;
    }
    align-items: flex-start;
    display: flex;
    align-content: space-around;
    flex-direction: row-reverse;

    .print {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .fullNav {
      display: flex;
      justify-content: center;
    }
    dt {
      display: flex;
      justify-content: center;
    }
    .print-dl {
      padding: 10px;
    }
    .print-div {
      padding-top: 0px !important;
    }
  }
}

.iban-icon:hover {
  cursor: pointer !important;
}

.mat-mdc-input-element {
  color: #666666 !important;
}

.mat-mdc-input-element:disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

mat-list-option[aria-selected='true'] {
  background-color: #16a8e0 !important;
  box-shadow: 0 1px 4px 2px #ccc;
  border-radius: 0.5rem !important;
  text-align: left !important;
  width: 100%;
  h4,
  p {
    color: #ffffff !important;
    margin: 3px 0px 0px 0px !important;
  }

  .mat-icon {
    color: #ffffff !important;
  }
}

.mdc-list-item--with-trailing-radio.mdc-list-item {
  padding-left: 0px;
  padding-right: 0px;
}

.center-box {
  padding-left: 20px;
  padding-right: 35px;
  text-wrap: pretty;
  margin-top: 5px !important;
}

mat-list-option[aria-selected='false'] {
  height: 92px !important;
  background-color: #e6e6e6 !important;
  box-shadow: 0 1px 4px 2px #ccc;
  border-radius: 0.5rem !important;
  text-align: left !important;
  width: 100%;
  h4,
  p {
    color: #999 !important;
    margin: 3px 0px 0px 0px !important;
  }

  .mat-icon {
    color: #999 !important;
  }
}

mat-list-option.real-time .mdc-list-item__content {
  height: 92px !important;
  .real-time-text {
    color: #ff922a !important;
    font-size: 12px !important;
    margin-top: 5px !important;
  }
}

.mat-pseudo-checkbox {
  display: none !important;
}

.mdc-list-item__end {
  display: none !important;
}

.mdc-list-item__content {
  height: 92px !important;

  h4,
  p {
    margin: 3px 0px 0px 0px !important;
    color: #999999 !important;
    line-height: normal;
  }

  h4 {
    font-weight: bold !important;
  }

  p {
    font-size: 11px !important;
  }

  border-radius: 0.5rem !important;
  text-align: left !important;
  width: 100%;

  .mat-icon {
    color: #999999;
  }
}

.sinpe-card:hover {
  box-shadow: #0066ab;
}

body {
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
}

.basic-container {
  padding: 30px;
}

.version-info {
  font-size: 8pt;
  float: right;
}

/* You can add global styles to this file, and also import other style files */
@import 'styles-variables';
@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';
@import './assets/kipo/impesa-theme';
@import 'bootstrap/scss/bootstrap';
@import './styles-variables.scss';
@import '../node_modules/normalize.css/normalize.css';

// if you'd like to use it without importing all of bootstrap it requires
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// global css variables
$base_font: 1rem;
$base_padding: 1rem;
$base_border_size: 1rem;
$border_color: #e5e5e5;
$base_margin: 1rem;

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  src:
    local('Fira Sans Regular'),
    local('FiraSans-Regular'),
    url(https://fonts.gstatic.com/s/firasans/v8/va9E4kDNxMZdWfMOD5Vvl4jLazX3dA.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src:
    local('Fira Sans Regular'),
    local('FiraSans-Regular'),
    url(https://fonts.gstatic.com/s/firasans/v8/va9E4kDNxMZdWfMOD5Vvl4jLazX3dA.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  src:
    local('Fira Sans Regular'),
    local('FiraSans-Regular'),
    url(https://fonts.gstatic.com/s/firasans/v8/va9E4kDNxMZdWfMOD5Vvl4jLazX3dA.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
  font-display: swap;
}

body,
html {
  height: 100%;
  background-color: #eeeeee;
  // background-image: url('./assets/images/kipo-patter-landing.png');
  background-repeat: repeat;
}

.sub-title {
  color: #999999;
  font-weight: normal;
  font-size: 14px;
}

.sub-disclaimer {
  color: #999999 !important;
  font-weight: normal !important;
  font-size: 12px !important;
  padding-bottom: 15px;
}

.statusbar {
  background-color: #0066ab;
}

.main {
  position: relative;
  display: block;
  min-height: 100%;
}

button {
  outline: none !important;
}

.btn {
  border-radius: 5px;
  color: #ffffff;
  min-width: 150px;
}

.btn-light {
  border: none;
  background-color: transparent;
  color: #999999;
}

.btn-secondary {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #ffffff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.panel {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 20px;
  border-radius: 1em;
  height: 100%;
}

h1 {
  color: var(--primary-color);
  font-size: 25px;
  font-weight: bold !important;
}

h2 {
  color: var(--primary-color);
  font-size: 22px;
  font-weight: bold !important;
}

h3 {
  color: #024787;
  font-size: 48px;
  font-weight: 200;
}

h4 {
  color: #0066ab;
  font-size: 50px;
  font-weight: 500;
}

h5 {
  color: #0066ab;
  font-size: 15px;
  font-weight: bold;
}

h6 {
  color: var(--primary-color);
  font-size: 40px;
  font-weight: bold;
}

span,
p {
  font-size: 15px;
  // color: #0066AB;
  font-weight: normal;
  text-align: justify;
}

.textReference {
  font-size: 21px;
  font-weight: normal;
  color: #024787;
}

a.textReference {
  text-decoration: none;
}

dl {
  line-height: 15px;
}

.alertsContainer {
  position: absolute;
  top: 83px !important;
}

.alertsContainer .material-icons {
  display: initial;
}

.alertsContainer .alertsRow {
  box-shadow: none !important;

  .messagepanel {
    padding: 15px !important;
    font-size: 14px;
    height: auto !important;
    max-width: 353px;
    min-width: 350px;
  }

  .iconpanel {
    color: #ffffff;
  }
}

.alert {
  padding: 0;
  margin-bottom: 0px;
}

.alert-danger {
  width: 100%;
  color: #cc330d;
  background-color: transparent;
  border-color: transparent;
}

.error {
  color: #e8230b !important;
}

.alert-danger > div {
  font-size: 11px;
}

td {
  color: black;
}

li[role='menuitem'] > a {
  display: block;
}

li[role='menuitem'] > a:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

//Bootstrap modal

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  margin-top: 15%;
}

.modal-footer {
  padding: 0px;
  border: none;
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .content-modal {
    padding: 10px !important;
  }

  .alertsContainer {
    top: 10px;
    right: 16px;
    margin-top: 63%;
  }

  .alertsContainer .alertsRow {
    .messagepanel {
      max-width: auto !important;
      min-width: auto !important;
      width: auto !important;
    }
  }

  .modal.show .modal-dialog {
    margin-top: 73%;
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: 890px) {
  .mat-horizontal-stepper-header {
    padding: 0px 0px 0px 20px !important;
  }
}

.toast {
  background-image: none !important;
  z-index: 999999;
  opacity: 1;
}

.toast-success {
  background-color: #0c9912 !important;
}

.toast-error {
  background-color: #bd362f !important;
}

.toast-top-right {
  top: 95px;
  right: 30px;
}

.toast-container .toast {
  padding: 15px;
}

.info-form {
  color: #999999;
  font-size: 11px !important;
}

input::placeholder {
  color: #cccccc !important;
}

.text-uppercase {
  text-transform: uppercase !important;
  font-family: Arvo, serif;
}

*:focus {
  outline: none;
}

*:active {
  outline: none;
}

.table th,
.table td {
  border: none !important;
}

.navbar {
  z-index: 99;
}

.mat-mdc-list-item {
  height: 92px !important;
  width: 98% !important;
  margin: 0 auto !important;
  margin-bottom: 0.6rem !important;
}

.mat-mdc-radio-button .mat-radio-button {
  margin-left: 16px;
}

.mat-mdc-radio-button .mat-radio-ripple {
  height: 20px; //double of your required circle radius
  width: 20px; //double of your required circle radius
  left: calc(50% - 10px); //'10px'-same as your required circle radius
  top: calc(50% - 10px); //'10px'-same as your required circle radius
}

.mat-mdc-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  background-color: #007dd1 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.cdk-overlay-pane {
  border-radius: 1em;
}

.mat-mdc-progress-spinner circle {
  stroke: var(--primary-color) !important;
}

.mat-icon.real-time {
  color: #999999;
  rotate: 22.5deg !important;
  animation-name: example !important;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  scale: 1.3;
}

@keyframes example {
  0% {
    color: #999999;
  }
  25% {
    color: #999999;
  }
  50% {
    color: #999999;
  }
  75% {
    color: #999999;
  }
  100% {
    color: var(--primary-color);
  }
}

.custom-modalbox {
  mat-dialog-container {
    padding: 0px !important;
    width: 40vw;
    border-radius: 1em 1em 1em 1em;

    @media only screen and (max-width: 1024px) {
      width: 80vw;
      max-height: 95vh;
    }
  }
}

.word-wrap {
  word-break: break-word;
}

body.app-theme-GTM
  .mdc-text-field--outlined
  .mdc-notched-outline
  .mdc-notched-outline__trailing,
body.app-theme-CRI
  .mdc-text-field--outlined
  .mdc-notched-outline
  .mdc-notched-outline__trailing,
body.app-theme-PAN
  .mdc-text-field--outlined
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

body.app-theme-GTM
  .mdc-text-field--outlined
  .mdc-notched-outline
  .mdc-notched-outline__leading,
body.app-theme-CRI
  .mdc-text-field--outlined
  .mdc-notched-outline
  .mdc-notched-outline__leading,
body.app-theme-PAN
  .mdc-text-field--outlined
  .mdc-notched-outline
  .mdc-notched-outline__leading {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.mat-mdc-snack-bar-container.snackbar-error .mdc-snackbar__surface {
  background: #bd362f !important;
  color: white !important;
}

.mat-mdc-snack-bar-container.snackbar-success .mdc-snackbar__surface {
  background: #0c9912 !important ;
  color: white !important;
}

.mat-mdc-snack-bar-container.snackbar-error .mdc-snackbar__surface,
.mat-mdc-snack-bar-container.snackbar-success .mdc-snackbar__surface,
.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: white !important;
  font-weight: bold;
  .mat-mdc-button {
    color: white !important;
    font-weight: bold;
  }
}

